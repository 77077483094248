import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSuitcase,
  faBuilding,
  faCar,
  faGlobe,
  faCalendar,
  faMinus,
  faPlus,
  faPlane,
  faSliders,
  faHotel,
  faJetFighter
} from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import ActivitySearch from './ActivitySearch'
import DatePicker from 'react-datepicker'
import { ToastContainer, toast } from 'react-toastify'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import Loader from '../Loading/Loader'
import { DateRangePicker } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import { Hotelapitoken, ApiEndPoint } from '../GlobalData/GlobalData'
import Autocomplete from 'react-google-autocomplete'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import moment from 'moment'
import Axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import HotelSearch from './HotelSearch'
import PackageSearch from './PackageSearch'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FlightSearch from './FlightSearch'
import TransferNew from './TransferNew'
import { countryListLocal } from '../Data/CountryList'
import {
  fetchHotels,
  fetchHotelsSearh,
  SearchToursDetail
} from '../../Redux/Actions/actions'
const { beforeToday } = DateRangePicker
function SearchBar () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  var endpoint = ApiEndPoint()
  const [selectedDate, setSelectedDate] = useState(null)
  const [places, setPlace] = useState([])
  const [initialRender, setInitialRender] = useState(true)
  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedNationality, setSelectedNationality] = useState('')
  const [loading, setLoading] = useState(false)
  const [personData, setPersonData] = useState({
    adult: 2,
    children: 0,
    room: 1
  })
  const [placeDetail, setPlaceDetail] = useState({
    city: '',
    country: '',
    countrycode: '',
    lat: 0,
    long: 0,
    checkindate: '',
    Checkoutdate: ''
  })
  const [rooms, setRooms] = useState([
    // Initial room with default values
    {
      adults: 2,
      children: 0,
      childrenAges: []
    }
  ])

  const [showtours, setShowtours] = useState(false)
  const [Showhotels, setShowhotels] = useState(true)
  const [Showflights, setFlights] = useState(false)
  const [Showtransfers, setShowtransfers] = useState(false)
  const [ShowActivities, setShowActivities] = useState(false)
  const [activeItem, setActiveItem] = useState(2)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768) // You can adjust the breakpoint as needed
  const [adultModal, setAdultModal] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState({
    payment: false,
    booking: false,
    trackid: false
  })
  const [dateRange, setDateRange] = useState({
    checkindate: null,
    Checkoutdate: null
  })
  // State for input value

  useEffect(() => {
    getUserLocation()
    const countryOptions = countryListLocal.item.map(country => ({
      value: country.name.common,
      label: country.name.common,
      flag: country.flags.png,
      phoneCode: country.idd.root + country.idd.suffixes[0]
    }))
    setCountryList(countryOptions)
    GetPackages()
  }, [dispatch])
  function getUserLocation () {
    // Fetch user's location data
    fetch(
      'https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041'
    )
      .then(response => response.json())
      .then(data => {
        setSelectedNationality(data.country.name)
        localStorage.setItem('UserCountry', data.country.name)
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error)
      })
  }

  const Searchhotels = async () => {
    if (places.length === 0) {
      toast.info('Please Select Destination.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    if (dateRange.Checkoutdate === null || dateRange.Checkoutdate === '') {
      toast.info('Please Select Hotel Stay.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (moment(placeDetail.checkindate).isSame(moment(), 'day')) {
      toast.info('Please Select a Future Check in Date.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    setLoading(true)
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      token:
        'r9fdvwRyF35JUnD6xXdRiDELANYjtfASzPmyGol4-1PN461EY50LbXcqkfEfISsOJDrnFDJbuMzPuxTz37zFWGWBVemQGhi2SYLrr-MZ75vJSAiV73z94UOVrDz5P6R-0KjFqr9XR6P2857snQbcKTUn9YNqjBOQQIkXENeO7tmjxdTJs2KUVoXqo6fFyT9TTq99eKe288N-wyanZXxOsfABWPjtSom2oKLVz6vJnn1WeQwHSp7VnzPUqq53rn80eFXNBSMIiEXBdDmlsokRYSa0evDrQKluhnIzMYkRiazxtnkb-z5Xj0tQReTTHsLz1sgnit2mRGGzP4EIdBK8TiLuEN7GD1kmOT3CMreL7ELrI4yxmEbnYyflICtG-ySk3aZkk8iM9mRZlA7CS10Zuj-C0HEBOFW8vMzy4Eq2CET5WN62S1xe0HPAOrDVwO6jDvVpKEMwm-NiyyjkU8oTTlgYpN77pXtfFjKPTF0julnAMC6cPzxZOGBIkRv0',
      currency_slc: 'AFN',
      currency_slc_iso: 'AF',
      destination_name: placeDetail.city,
      country: placeDetail.country,
      lat: placeDetail.lat,
      long: placeDetail.long,
      pin: placeDetail.countrycode,
      cityd: placeDetail.city,
      country_code: placeDetail.countrycode,
      // "daterange_date": placeDetail.checkindate+'-'+placeDetail.Checkoutdate,
      check_in: placeDetail.checkindate,
      check_out: placeDetail.Checkoutdate,
      slc_nationality: String(selectedNationality),
      ...rooms.reduce(
        (acc, item, index) => {
          acc.adult += item.adults
          acc.child += item.children
          acc.room += 1
          acc.Adults.push(item.adults)
          acc.children.push(item.children)
          acc[`child_ages${index + 1}`] = item.childrenAges.map(age =>
            age ? parseInt(age) : 2
          )
          acc.rooms_counter.push(acc.room)
          return acc
        },
        {
          adult: 0,
          child: 0,
          room: 0,
          Adults: [],
          children: [],
          child_ages1: [],
          rooms_counter: [],
          child_ages2: []
        }
      )
    }
    // navigate('/hotels',{state:{FormData}});
    try {
      dispatch(fetchHotelsSearh(FormData))
      // _Live
      const response = await Axios.post(
        endpoint + '/api/search/hotels/new_Live',
        FormData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      if (response?.data?.hotels_list.length === 0) {
        setLoading(false)
        toast.info('Sorry! no hotels found in given search.')
        return
      }
      sessionStorage.removeItem('FlightCheckOut')
      navigate('/hotels')
      dispatch(fetchHotels(response.data))
      // Handle the API response here
      setLoading(false)
    } catch (error) {
      // Handle errors here
      setLoading(false)
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768) // Update isMobile based on the screen width
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    // Calculate the total counts from the inputArray
    const adults = rooms.reduce((acc, item) => acc + item.adults, 0)
    const children = rooms.reduce((acc, item) => acc + item.children, 0)
    const roomss = rooms.length
    setPersonData(prevPersonData => ({
      ...prevPersonData,
      adult: adults,
      children: children,
      room: roomss
      // Use square brackets to set the property dynamically based on the 'name' attribute
    }))
    // Update the state with the calculated totals
  }, [rooms])

  // async function fetchData () {
  //   Axios.get('https://restcountries.com/v3.1/all?fields=name,flags,cca2,cca3,idd')
  //         .then(response => {
  //           const countryOptions = response?.data.map((country) => ({
  //               value:country.name.common,
  //               label: country.name.common,
  //               flag: country.flags.png,
  //               phoneCode: country.idd.root+country.idd.suffixes[0],
  //             }));
  //           setCountryList(countryOptions)
  //         })
  //         .catch(error => {
  //           console.error('Error:', error)
  //         })
  // };
  // Callback function to handle date range changes
  const print = () => {
    // console.log(placeDetail)
    // console.log(selectedNationality)
    // console.log(personData)
    // console.log(rooms)
  }

  const GetPackages = async () => {
    var token = Hotelapitoken()
    const fullURL = window.location.href
    var data = {
      token: token,
      currentURL: fullURL,
      limit: 6
    }
  }

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false) // Update the initialRender state to false.
      return // Exit the effect early to avoid running the code below.
    }

    if (places.length !== 0) {
      // debugger
      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]
      ) {
        const city1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]?.short_name
        // console.log('city1=' + city1)
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          city: city1
        }))
      } else {
        const city1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['administrative_area_level_2', 'political'])
        )[0]?.short_name
        // console.log('city1=' + city1)
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          city: city1
        }))
      }

      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]
      ) {
        const displayCity1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]?.long_name
        // console.log('displayCity1=' + displayCity1)
      } else {
        const displayCity1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['administrative_area_level_2', 'political'])
        )[0]?.long_name
        // console.log('displayCity1=' + displayCity1)
      }

      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]
      ) {
        const country_code = places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]?.short_name
        // console.log('country_code=' + country_code)
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          countrycode: country_code
        }))
      }
      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]
      ) {
        const country = places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]?.long_name
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          country: country
        }))
      }
      var address = places.formatted_address
      var latitude = places.geometry.location.lat()
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        lat: latitude
      }))
      var longitude = places.geometry.location.lng()
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        long: longitude
      }))
      const latlng = new window.google.maps.LatLng(latitude, longitude)
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ latLng: latlng }, function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const addressComponents = results[0].address_components
            const lastAddressComponent =
              addressComponents[addressComponents.length - 1]
            const country = lastAddressComponent.short_name
            const state =
              addressComponents[addressComponents.length - 3].long_name
            const city =
              addressComponents[addressComponents.length - 4].long_name
          }
        }
      })
    }
  }, [places, initialRender])

  const toggleModal = divNumber => {
    if (divNumber === 1) {
      setModalIsOpen({ payment: !modalIsOpen.payment })
    } else if (divNumber === 2) {
      setModalIsOpen({ booking: !modalIsOpen.booking })
    } else if (divNumber === 3) {
      setModalIsOpen({ trackid: !modalIsOpen.trackid })
    }
  }
  const handleDateChange = date => {
    setSelectedDate(date)
  }

  const handleCountryChange = selectedOption => {
    // debugger
    setSelectedCountry(selectedOption)
    setSelectedNationality(selectedOption.value) // Update the selected nationality when the user makes a selection
  }

  const toggleDiv = divNumber => {
    if (divNumber === 1) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setFlights(false)
      setShowtours(true)
      setShowActivities(false)
      setShowhotels(false) // Hide div2 when showing div1
    } else if (divNumber === 2) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setShowhotels(true)
      setFlights(false)
      setShowActivities(false)
      setShowtours(false) // Hide div1 when showing div2
    } else if (divNumber === 3) {
      setActiveItem(divNumber)
      setShowtransfers(true)
      setShowhotels(false)
      setShowActivities(false)
      setFlights(false)
      setShowtours(false) // Hide div1 when showing div2
    } else if (divNumber === 4) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setShowhotels(false)
      setShowActivities(false)
      setFlights(true)
      setShowtours(false) // Hide div1 when showing div2
    } else if (divNumber === 5) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setShowhotels(false)
      setShowActivities(true)
      setFlights(false)
      setShowtours(false) // Hide div1 when showing div2
    }
  }
  const toggleAdultModal = () => {
    setAdultModal(!adultModal)
  }
  const childAgearray = [
    '1 Year',
    '2 Year',
    '3 Year',
    '4 Year',
    '5 Year',
    '6 Year',
    '7 Year',
    '8 Year',
    '9 Year',
    '10 Year',
    '11 Year',
    '12 Year',
    '13 Year',
    '14 Year',
    '15 Year',
    '16 Year',
    '17 Year'
  ]
  const handleDateRangeChange = value => {
    if (value === null) {
      // Dates have been removed, reset the date range state
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        checkindate: '',
        Checkoutdate: ''
      }))

      // Reset the dateRange state
      setDateRange(null)
    } else {
      // Dates are selected, format and update the state
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        checkindate: moment(value[0]).format('YYYY-MM-DD'),
        Checkoutdate: moment(value[1]).format('YYYY-MM-DD')
      }))

      // Update the dateRange state
      setDateRange(value)
    }
  }

  const handleAdultIncrement = roomIndex => {
    // Increment the number of adults for a specific room
    const updatedRooms = [...rooms]
    updatedRooms[roomIndex].adults += 1
    setRooms(updatedRooms)
  }

  const handleAdultDecrement = roomIndex => {
    // Decrement the number of adults for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].adults > 1) {
      updatedRooms[roomIndex].adults -= 1
      setRooms(updatedRooms)
    }
  }

  const handleChildrenIncrement = roomIndex => {
    // Increment the number of children for a specific room, if less than 4
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].children < 4) {
      updatedRooms[roomIndex].children += 1
      setRooms(updatedRooms)
    }
  }

  const handleChildrenDecrement = roomIndex => {
    // Decrement the number of children for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].children > 0) {
      updatedRooms[roomIndex].children -= 1
      setRooms(updatedRooms)
    }
  }

  const handleChildAgeChange = (roomIndex, childIndex, value) => {
    // Update the age of a specific child for a specific room
    const updatedRooms = [...rooms]
    updatedRooms[roomIndex].childrenAges[childIndex] = value
    setRooms(updatedRooms)
  }
  const addRoom = () => {
    // Add a new room with default values
    setRooms([...rooms, { adults: 2, children: 0, childrenAges: [] }])
  }

  const removeLastRoom = () => {
    if (rooms.length > 1) {
      // Remove the last room by slicing the array
      setRooms(rooms.slice(0, -1))
    }
  }
  const handleDeleteRoomByIndex = roomIndex => {
    const updatedRooms = rooms.filter((room, index) => index !== roomIndex)
    setRooms(updatedRooms)
  }
  return (
    <>
      <ToastContainer />
      <Modal isOpen={adultModal} centered={true} toggle={toggleAdultModal}>
        <ModalHeader toggle={toggleAdultModal}>Select Detail</ModalHeader>
        <ModalBody>
          <div className='p-3'>
            {rooms.map((room, roomIndex) => (
              <div key={roomIndex}>
                <div
                  className='hotel-detail-border d-flex text-center fw-bold mb-2'
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <p className='fw-bold'>Room {roomIndex + 1}</p>
                  {roomIndex > 0 && ( // Check if roomIndex is greater than 0
                    <button
                      className='delete-room-button m-1'
                      onClick={() => handleDeleteRoomByIndex(roomIndex)}
                    >
                      Delete
                    </button>
                  )}
                </div>
                <div>
                  <div className='select-child'>
                    <div class='f4878764f1'>
                      <label class='a984a491d9 fw-bold' for='group_adults'>
                        Adults
                      </label>
                    </div>
                    <div class='d-flex fff'>
                      <button
                        className='adult-modal-btn'
                        name={`adults-${roomIndex}`}
                        onClick={() => handleAdultDecrement(roomIndex)}
                      >
                        <i class='fas fa-minus'>
                          <FontAwesomeIcon size='sm' icon={faMinus} />
                        </i>
                      </button>
                      <span className='d723d73d5f fw-bold' id='input'>
                        {room.adults}
                      </span>
                      <button
                        className='adult-modal-btn'
                        name={`adults-${roomIndex}`}
                        onClick={() => handleAdultIncrement(roomIndex)}
                        id='increment'
                      >
                        <i class='fas fa-plus'>
                          <FontAwesomeIcon size='sm' icon={faPlus} />
                        </i>
                      </button>
                    </div>
                  </div>
                  <div className='select-child'>
                    <div class='f4878764f1'>
                      <label class='a984a491d9 fw-bold' for='group_adults'>
                        Children
                      </label>
                    </div>
                    <div class='d-flex fff'>
                      <button
                        className='adult-modal-btn'
                        onClick={() => handleChildrenDecrement(roomIndex)}
                        id='decrement'
                      >
                        <i class='fas fa-minus'>
                          <FontAwesomeIcon size='sm' icon={faMinus} />
                        </i>
                      </button>
                      <span className='d723d73d5f fw-bold' id='input'>
                        {room.children}
                      </span>
                      <button
                        className='adult-modal-btn'
                        onClick={() => handleChildrenIncrement(roomIndex)}
                        id='increment'
                      >
                        <i class='fas fa-plus'>
                          <FontAwesomeIcon size='sm' icon={faPlus} />
                        </i>
                      </button>
                    </div>
                  </div>
                  <div
                    className='select-child'
                    style={{ justifyContent: 'left' }}
                  >
                    {room.children > 0 && (
                      <div className='row w-100 '>
                        {Array.from(
                          { length: room.children },
                          (_, childIndex) => (
                            <div
                              key={childIndex}
                              className='m-1 '
                              style={{ width: '45%', alignItems: 'center' }}
                            >
                              <label>Child {childIndex + 1} (Age)</label>
                              <select
                                class='form-select child-age-select'
                                name='child1'
                                onChange={e =>
                                  handleChildAgeChange(
                                    roomIndex,
                                    childIndex,
                                    e.target.value
                                  )
                                }
                                aria-label='Default select example'
                              >
                                <option selected>Age needed</option>
                                {childAgearray.map((item, index) => (
                                  <option key={index} value={index + 1}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='select-child'>
            <div class='f4878764f1 pe-4'>
              <label class='a984a491d9 fw-bold' for='group_adults'>
                Room
              </label>
            </div>
            <div class='d-flex fff'>
              <button
                className='adult-modal-btn'
                onClick={removeLastRoom}
                id='decrement'
              >
                <i class='fas fa-minus'>
                  <FontAwesomeIcon size='sm' icon={faMinus} />
                </i>
              </button>
              <span className='d723d73d5f fw-bold' id='input'>
                {rooms.length}
              </span>
              <button
                className='adult-modal-btn'
                onClick={addRoom}
                id='increment'
              >
                <i class='fas fa-plus'>
                  <FontAwesomeIcon size='sm' icon={faPlus} />
                </i>
              </button>
            </div>
          </div>
          <Button color='secondary' onClick={toggleAdultModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {/***********  Model Windows ****************/}
      <Modal isOpen={modalIsOpen.payment} toggle={() => toggleModal(1)}>
        <ModalHeader toggle={() => toggleModal(1)}>Make Payment</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <label htmlFor=''>Enter Invoice No.</label>
            <input
              type='text'
              className='form-control select-styling mt-2'
              id=''
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => toggleModal(1)}>
            Close
          </Button>
          <button
            className=' btn btn-warning modal-btn1'
            onClick={() => toggleModal(1)}
          >
            Submit
          </button>
        </ModalFooter>
      </Modal>
      {/***********  Model Windows ****************/}
      <Modal isOpen={modalIsOpen.booking} toggle={() => toggleModal(2)}>
        <ModalHeader toggle={() => toggleModal(2)}>View Booking</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <label htmlFor=''>Enter Email.</label>
            <input
              type='email'
              className='form-control mt-2'
              id='exampleInputEmail1'
              aria-describedby='emailHelp'
              placeholder=''
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => toggleModal(2)}>
            Close
          </Button>
          <button
            className=' btn btn-warning modal-btn1'
            onClick={() => toggleModal(2)}
          >
            Submit
          </button>
        </ModalFooter>
      </Modal>
      {/***********  Model Windows ****************/}
      <Modal isOpen={modalIsOpen.trackid} toggle={() => toggleModal(3)}>
        <ModalHeader toggle={() => toggleModal(3)}>Track ID</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <label htmlFor=''>Enter Track ID.</label>
            <input
              type='text'
              className='form-control select-styling mt-2'
              id=''
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => toggleModal(3)}>
            Close
          </Button>
          <button className=' btn btn-warning modal-btn1' onClick={print}>
            Submit
          </button>
        </ModalFooter>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <section className='booking_part mt-3'>
          <div className='text-center mb-4'>
            <h1
              class='font_0 wixui-rich-text__text'
              style={{ fontSize: '65px', lineHeight: '0.9em' }}
            >
              <span className='hajj-h-1 text-white'>HAJJ</span>{' '}
              <span className='hajj-h-2 '>2025</span>
            </h1>
            <div
              style={{ flexWrap: 'wrap' }}
              className='d-flex  hajj-links-2 justify-content-center align-items-center'
            >
              <div>
                <a
                  class='nav-link '
                  href='https://chat.whatsapp.com/IfJtMNZQXerDgcltof7HGy'
                  target='blank'
                >
                  <svg
                    fill='#279b3e'
                    height='18px'
                    width='18px'
                    viewBox='0 0 308 308'
                    stroke='#279b3e'
                  >
                    <g>
                      <path
                        d='M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
        c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
        c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
        c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
        c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
        c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
        c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
        c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
        c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
        C233.168,179.508,230.845,178.393,227.904,176.981z'
                      />
                      <path
                        d='M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
        c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
        c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
        M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
        l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
        c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
        C276.546,215.678,222.799,268.994,156.734,268.994z'
                      />
                    </g>
                  </svg>{' '}
                  JOIN HAJJ WHATSAPP GROUP
                </a>
              </div>
              <div>
                <a
                  class='nav-link '
                  href='https://t.me/+tF5MQ2KCCCU1Y2Zk'
                  target='_blank'
                >
                  <svg
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#2180ba'
                    height='18px'
                    width='18px'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M23.1117 4.49449C23.4296 2.94472 21.9074 1.65683 20.4317 2.227L2.3425 9.21601C0.694517 9.85273 0.621087 12.1572 2.22518 12.8975L6.1645 14.7157L8.03849 21.2746C8.13583 21.6153 8.40618 21.8791 8.74917 21.968C9.09216 22.0568 9.45658 21.9576 9.70712 21.707L12.5938 18.8203L16.6375 21.8531C17.8113 22.7334 19.5019 22.0922 19.7967 20.6549L23.1117 4.49449ZM3.0633 11.0816L21.1525 4.0926L17.8375 20.2531L13.1 16.6999C12.7019 16.4013 12.1448 16.4409 11.7929 16.7928L10.5565 18.0292L10.928 15.9861L18.2071 8.70703C18.5614 8.35278 18.5988 7.79106 18.2947 7.39293C17.9906 6.99479 17.4389 6.88312 17.0039 7.13168L6.95124 12.876L3.0633 11.0816ZM8.17695 14.4791L8.78333 16.6015L9.01614 15.321C9.05253 15.1209 9.14908 14.9366 9.29291 14.7928L11.5128 12.573L8.17695 14.4791Z'
                      fill='#2d7fbe'
                    />
                  </svg>{' '}
                  JOIN HAJJ TELEGRAM CHANNEL
                </a>
              </div>
              <div>
                <NavLink class='nav-link ' target='blank' to='/hajj'>
                  REGISTER YOUR INTEREST
                </NavLink>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '5em' }} className='row search-border'>
            <div className='col-lg-12 ps-0 pe-0'>
              {/* <div className='booking_menu '>
              <div className='search-menu-buttons'>
                <div className='tab-btn-right'>
                  <button
                    onClick={() => toggleModal(1)}
                    className='btn btn-info ms-2'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModalpayment'
                  >
                     {t('Make Payment')}
                  </button>
                  <button
                    onClick={() => toggleModal(2)}
                    className='btn btn-info ms-2'
                    data-bs-toggle='modal'
                    data-bs-target='#view_booking'
                  >
                     {t('View Booking')}
                  </button>
                  <button
                    onClick={() => toggleModal(3)}
                    className='btn btn-info ms-2'
                    data-bs-toggle='modal'
                    data-bs-target='#view_track_id_booking'
                  >
                     {t('Track ID')}
                  </button>
                </div>
              </div>
              <div className='search-menu'>
                <ul className='nav nav-tabs' id='myTab' role='tablist'>
                <li
                    className={`nav-item ${
                      activeItem === 4 ? 'active-search' : ''
                    }`}
                    onClick={() => toggleDiv(4)}
                  >
                    <a className='nav-link'>
                      <FontAwesomeIcon icon={faPlane} />  {t('Flights')}
                    </a>
                  </li>
                <li
                    className={`nav-item ${
                      activeItem === 2 ? 'active-search' : ''
                    }`}
                    onClick={() => toggleDiv(2)}
                  >
                    <a className='nav-link'>
                      <FontAwesomeIcon icon={faBuilding} />  {t('Hotels')} 
                    </a>
                  </li>
                  <li
                    className={`nav-item ${
                      activeItem === 1 ? 'active-search' : ''
                    }`}
                    onClick={() => toggleDiv(1)}
                  >
                    <a className='nav-link'>
                      <FontAwesomeIcon icon={faSuitcase} /> {t('Packages')}
                    </a>
                  </li>
                  <li
                    className={`nav-item ${
                      activeItem === 5 ? 'active-search' : ''
                    }`}
                    onClick={() => toggleDiv(5)}
                  >
                    <a className='nav-link'>
                      <FontAwesomeIcon icon={faSliders} /> {t('Activities')}
                    </a>
                  </li>
                  <li
                    className={`nav-item ${
                      activeItem === 3 ? 'active-search' : ''
                    }`}
                    onClick={() => toggleDiv(3)}
                  >
                    <a className='nav-link'>
                      <FontAwesomeIcon icon={faCar} /> {t('Transfers')} 
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
              <div class='navTabbs d-flex align-items-center justify-content-center w-100 mb-2'>
                <ul
                  class='nav nav-pills lights medium justify-content-center mb-3'
                  id='tour-pills-tab'
                  role='tablist'
                >
                  <li class='nav-item mt-1' role='presentation'>
                    <a
                      class={`nav-link ${activeItem === 2 ? 'active' : ''}`}
                      data-bs-toggle='tab'
                      onClick={() => toggleDiv(2)}
                      aria-selected='true'
                      role='tab'
                    >
                      <i class='fa-solid fa-hotel me-2'>
                        <FontAwesomeIcon icon={faHotel} />
                      </i>
                      Hotels
                    </a>
                  </li>
                  <li class='nav-item mt-1' role='presentation'>
                    <a
                      class={`nav-link ${activeItem === 4 ? 'active' : ''}`}
                      data-bs-toggle='tab'
                      onClick={() => toggleDiv(4)}
                      aria-selected='false'
                      role='tab'
                      tabindex='-1'
                    >
                      <i class='fa-solid fa-jet-fighter me-2'>
                        <FontAwesomeIcon icon={faJetFighter} />
                      </i>
                      Flights
                    </a>
                  </li>
                  <li class='nav-item mt-1' role='presentation'>
                    <a
                      class={`nav-link ${activeItem === 1 ? 'active' : ''}`}
                      data-bs-toggle='tab'
                      onClick={() => toggleDiv(1)}
                      aria-selected='false'
                      role='tab'
                      tabindex='-1'
                    >
                      <i class='fa-solid fa-globe me-2'></i>{' '}
                      <FontAwesomeIcon icon={faSuitcase} /> {t('Packages')}
                    </a>
                  </li>
                  <li class='nav-item mt-1' role='presentation'>
                    <a
                      class={`nav-link ${activeItem === 5 ? 'active' : ''}`}
                      data-bs-toggle='tab'
                      onClick={() => toggleDiv(5)}
                      aria-selected='false'
                      role='tab'
                      tabindex='-1'
                    >
                      <i class='fa-solid fa-car me-2'></i>{' '}
                      <FontAwesomeIcon icon={faSliders} /> {t('Activities')}
                    </a>
                  </li>
                  <li class='nav-item mt-1' role='presentation'>
                    <a
                      class={`nav-link ${activeItem === 3 ? 'active' : ''}`}
                      data-bs-toggle='tab'
                      onClick={() => toggleDiv(3)}
                      aria-selected='false'
                      role='tab'
                      tabindex='-1'
                    >
                      <i class='fa-solid fa-car me-2'></i>{' '}
                      <FontAwesomeIcon icon={faCar} /> {t('Transfers')}{' '}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-12 ps-0 pe-0'>
              <div className='booking_content'>
                <div className='tab-content'>
                  <div className='tab-pane fade show active'>
                    <div className='row check-availabilty'>
                      {showtours && <PackageSearch />}
                      {Showflights && <FlightSearch />}
                      {Showhotels && (
                        <div className='block-32'>
                          <div className='row'>
                            <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                              {/* <label
                                htmlFor='checkin_date'
                                className='font-weight-bold text-black mb-2'
                              >
                                Destinations
                              </label> */}
                              <div className='field-icon-wrap'>
                                <span className='form-control-feedback'>
                                  <FontAwesomeIcon icon={faGlobe} />{' '}
                                </span>
                                <Autocomplete
                                  className='form-control search-form-control height-25 text-start select-styling '
                                  placeholder='Enter Location'
                                  apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'
                                  onPlaceSelected={place => {
                                    setPlace(place)
                                  }}
                                  options={{
                                    types: ['establishment', 'geocode'],
                                    componentRestrictions: null
                                  }}
                                  language='en'
                                />
                              </div>
                            </div>
                            <div className='col-md-6  mb-lg-0 col-lg-3  mt-2'>
                              {/* <label
                                htmlFor='checkin_date'
                                className='font-weight-bold text-black mb-2'
                              >
                                Hotel Stay
                              </label> */}
                              <div className='field-icon-wrap'>
                                <DateRangePicker
                                  onChange={handleDateRangeChange}
                                  placeholder='Check in ~ Check out'
                                  value={dateRange}
                                  showOneCalendar={isMobile}
                                  disabledDate={beforeToday()}
                                  className='w-100 text-left select-styling '
                                />
                              </div>
                            </div>
                            <div className='col-md-6  mb-lg-0 col-lg-4  mt-2'>
                              {/* <label
                                htmlFor='checkin_date'
                                className='font-weight-bold text-black mb-2'
                              >
                                Select Rooms
                              </label> */}
                              <div className='field-icon-wrap'>
                                <button
                                  onClick={toggleAdultModal}
                                  className='btn text-left  btn-primary btn-block select-styling button-2 search-btn1'
                                  style={{
                                    color: 'black',
                                    background: 'none',
                                    lineHeight: '2.5em'
                                  }}
                                >
                                  {personData.adult} Adults .{' '}
                                  {personData.children} Children .{' '}
                                  {personData.room} Room
                                </button>
                              </div>
                            </div>
                            {/* <div className='col-md-6 mb-lg-0 col-lg-3 mt-2'>
                              <label
                                htmlFor='checkin_date'
                                className='font-weight-bold text-black mb-2'
                              >
                                Nationality
                              </label>
                              <div className='field-icon-wrap'>
                              <Select
                                        options={countryList}
                                        isSearchable={true}
                                        className="mt-2"
                                        onChange={handleCountryChange}
                                        value={selectedCountry}
                                        getOptionLabel={(option) => (
                                            <div>
                                            <img
                                                src={option.flag}
                                                alt={option.label}
                                                style={{ width: '20px', marginRight: '8px' }}
                                            />
                                            {option.label}
                                            </div>
                                        )}
                                        getOptionValue={(option) => option.value}
                                        />
                              </div>
                            </div> */}
                            <div className='col-md-6 col-lg-2 text-center   mt-2 align-self-end'>
                              {/* <NavLink to='/hotels'> */}
                              <button
                                onClick={Searchhotels}
                                className='btn btn-primary height-25 btn-block select-styling search-btn1'
                              >
                                Search
                              </button>
                              {/* </NavLink> */}
                            </div>
                          </div>
                        </div>
                      )}
                      {Showtransfers && (
                        <TransferNew />
                        // <div className='block-32'>

                        //     <div className='row'>
                        //       <div className='col-md-6 mb-3 mb-lg-0 col-lg-3'>
                        //         <label
                        //           htmlFor='checkin_date'
                        //           className='font-weight-bold text-black mb-2'
                        //         >
                        //           Picking up
                        //         </label>
                        //         <div className='field-icon-wrap'>
                        //           <span className='form-control-feedback'>
                        //             <FontAwesomeIcon icon={faGlobe} />{' '}
                        //           </span>
                        //           <input
                        //             type='text'
                        //             className='form-control select-styling ps-5'
                        //             placeholder='City,airport'
                        //           />
                        //         </div>
                        //       </div>
                        //       <div className='col-md-6 mb-3 mb-lg-0 col-lg-3'>
                        //         <label
                        //           htmlFor='checkin_date'
                        //           className='font-weight-bold text-black mb-2'
                        //         >
                        //           Droping off
                        //         </label>
                        //         <div className='field-icon-wrap'>
                        //           <span className='form-control-feedback'>
                        //             <FontAwesomeIcon icon={faGlobe} />{' '}
                        //           </span>
                        //           <input
                        //             type='text'
                        //             className='form-control select-styling ps-5'
                        //             placeholder='City,airport'
                        //           />
                        //         </div>
                        //       </div>
                        //       <div className='col-md-6 mb-3 mb-lg-0 col-lg-2'>
                        //         <label
                        //           htmlFor='checkin_date'
                        //           className='font-weight-bold text-black mb-2'
                        //         >
                        //           Picking Date
                        //         </label>
                        //         <div className='field-icon-wrap'>
                        //           <span className='form-control-feedback'>
                        //             <FontAwesomeIcon icon={faCalendar} />{' '}
                        //           </span>
                        //           <DatePicker
                        //             selected={selectedDate}
                        //             onChange={handleDateChange}
                        //             placeholderText='Date'
                        //             dateFormat='dd/MM/yyyy' // Customize date format as needed
                        //             className='form-control date-pading select-styling '
                        //           />
                        //         </div>
                        //       </div>
                        //       <div className='col-md-6 mb-3 mb-lg-0 col-lg-2'>
                        //         <label
                        //           htmlFor='checkin_date'
                        //           className='font-weight-bold text-black mb-2'
                        //         >
                        //           Droping Date
                        //         </label>
                        //         <div className='field-icon-wrap'>
                        //           <span className='form-control-feedback'>
                        //             <FontAwesomeIcon icon={faCalendar} />{' '}
                        //           </span>
                        //           <DatePicker
                        //             selected={selectedDate}
                        //             onChange={handleDateChange}
                        //             placeholderText='Date'
                        //             dateFormat='dd/MM/yyyy' // Customize date format as needed
                        //             className='form-control date-pading select-styling '
                        //           />
                        //         </div>
                        //       </div>
                        //       <div className='col-md-6 col-lg-2  align-self-end mt-3'>
                        //         <button className='btn btn-primary btn-block select-styling search-btn1'>
                        //           Find My Car
                        //         </button>
                        //       </div>
                        //     </div>

                        // </div>
                      )}
                      {ShowActivities && <ActivitySearch />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default SearchBar
