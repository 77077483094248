import React, { useState, useEffect, useCallback } from 'react';
import img1 from '../../Images/Packages/umrah-package.jpg';
import img2 from '../../Images/Packages/AlAqsa-package.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeadset,
  faPersonWalkingLuggage,
  faPlaneArrival,
  faPlaneDeparture,
  faSackDollar,
  faUmbrellaBeach,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import CIcon from '@coreui/icons-react';
import { cilMoon } from '@coreui/icons';
import Loading from '../Loading/Loader';
import { useNavigate } from 'react-router-dom';
import { ViewTourDetail } from '../../Redux/Actions/actions';
import { useDispatch } from 'react-redux';
import { Hotelapitoken, CustomerDomainName, ApiEndPoint } from '../GlobalData/GlobalData';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
function Packages() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [isLoading, setLoading] = useState(false);
  const [packagesData, setPackagesData] = useState({ categories: [], tours: [] });
  const [showTours, setShowTours] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(4);
  const [activeItem, setActiveItem] = useState(0);
  const url = CustomerDomainName();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiendpoint = ApiEndPoint();

  useEffect(() => {
    if (packagesData.categories.length > 0 && packagesData.tours.length > 0) {
      const filteredTours = packagesData.tours.flat().filter(
        (tour) => tour.categories === packagesData.categories[activeItem].id.toString()
      );
      var newlist = filteredTours.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
      setShowTours(newlist);
    }
  }, [activeItem]);
  // Fetch data once on initial load
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setLoading(true);
    const token = Hotelapitoken();
    const data = { token: token };

    try {
      const response = await Axios.post(
        `${apiendpoint}/api/latest_packages`,
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        }
      );
      const allCategories = response.data.all_cateogries;
      const allTours = response.data.latest_packages;

      setPackagesData({ categories: allCategories, tours: allTours });

      // Initialize showTours with the tours from the first category
      const initialTours = allTours.flat().filter(
        (tour) => tour.categories === allCategories[0].id.toString()
      );
      // setShowTours(initialTours.sort((a, b) => new Date(a.start_date) - new Date(b.start_date)));
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };
  // Update showTours whenever activeItem changes
  // useEffect(() => {
  //   if (packagesData.categories.length > 0 && packagesData.tours.length > 0) {
  //     const filteredTours = packagesData.tours.flat().filter(
  //       (tour) => tour.categories === packagesData.categories[activeItem].id.toString()
  //     );
  //     setShowTours(filteredTours.sort((a, b) => new Date(a.start_date) - new Date(b.start_date)));
  //   }
  // }, [activeItem, packagesData]);

  const handleCategorySelect = (index) => {
    setActiveItem(index);
  };


  const fetchViewDetail = async (event, id, name) => {
    event.preventDefault();
    const token = Hotelapitoken();
    setLoading(true);
    const data = {
      token: token,
      id: id,
      type: 'tour',
      provider_token: '',
    };

    try {
      const response = await Axios.post(
        `${apiendpoint}/api/get_tour_details`,
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        }
      );
      dispatch(ViewTourDetail(response.data));
      const result = name.split(' ').join('-');
      navigate(`/umrah-package/${result}`);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Add an event listener to track window width changes
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setItemsToShow(4);
      } else if (window.innerWidth > 768 && window.innerWidth < 1000) {
        setItemsToShow(3);
      } else if (window.innerWidth > 530 && window.innerWidth < 768) {
        setItemsToShow(2);
      } else if (window.innerWidth < 530) {
        setItemsToShow(1);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {isLoading && <Loading />}
      <div className="container">
        <div className="row">
          <div className="section-title mt-5">
            <h4 className="wow animate__animated animate__fadeInRight" data-wow-duration="1.5s" data-wow-delay="0.3s">
              {t('Welcome to Alhijaz Tours')}
            </h4>
            {language === 'arabic' ? (
              <h2 className="wow animate__animated animate__fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.3s">
                {t('ALHIJAZ TOURS SPECIAL PACKAGES')}
              </h2>
            ) : (
              <h2 className="wow animate__animated animate__fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.3s">
                Alhijaz Tours <span>Special </span> Packages
              </h2>
            )}
          </div>
        </div>
        <section className='recommended' id='deals'>
        <div class="row mt-5">
                <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
               
            </div>
            </div>
            </section>

        <div className="row">
          {packagesData.categories.map((item, index) => (
            <div
              key={item.id}
              className="package-filter wow animate__animated animate__slideInRight text-center w-auto col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <button
                onClick={() => handleCategorySelect(index)}
                className={`btn btn-success ${activeItem === index ? 'activefilter' : ''
                  } btn-block btn-md text-start center-vertically`}
              >
                <img
                  src={url + '/public/uploads/package_imgs/' + item.image}
                  width="50"
                  height="50"
                  alt={item.title}
                />{' '}
                <span className="ms-2 me-2">{t(`${item.title}`)}</span>
              </button>
            </div>
          ))}
        </div>
        <hr className="dashed hr-devider"></hr>
        {packagesData.tours.length > 0 && (
          <div className='row recommended'>
            {packagesData.tours.flat().filter(
              (tour) => tour.categories === packagesData.categories[activeItem].id.toString()
            ).slice(0,3).map((tour, index) => (
              <div className='col-lg-4 col-sm-6 col-12 mt-2'>
                 <div class="pop-touritem" onClick={(event) => fetchViewDetail(event, tour.id, tour.title)}>
							<a href="#" class="card rounded-3 border m-0">
								<div class="flight-thumb-wrapper">
									<div class="popFlights-item-overHidden">
										<img src={url + '/public/uploads/package_imgs/' + tour.tour_banner_image} class="img-fluid" alt=""/>
									</div>
								</div>
								<div class="touritem-middle position-relative p-3">
									<div class="touritem-flexxer">
										<h4 class="city fs-title m-0 fw-bold">
											<span>{tour.title}</span>
										</h4>
										<p class="detail ellipsis-container">
											<span class="ellipsis-item__normal">{tour.time_duration} </span>
											<span class="separate ellipsis-item__normal"></span>
											<span class="ellipsis-item">Nights</span>
										</p>

										<div class="touritem-centrio mt-4">
											
                          <div className="d-flex bg-light-success p-2 text-success justify-content-between">
                      <div className=" medium">
                        <FontAwesomeIcon icon={faPlaneDeparture} />{' '}
                        {moment(tour.start_date).format('DD-MM-YYYY')}
                      </div>
                      <div className="medium">
                        <FontAwesomeIcon icon={faPlaneArrival} />{' '}
                        {moment(tour.end_date).format('DD-MM-YYYY')}
                      </div>
                    </div>
										</div>
									</div>
									<div class="trsms-foots mt-4">
										<div class="flts-flex d-flex align-items-end justify-content-between">
											<div class="flts-flex-strat">
											
												<div class="d-flex align-items-center">
													<div class="text-dark fw-bold fs-4">
                          {tour.currency_symbol}{' '}
                      {tour.quad_grand_total_amount !== null
                        ? tour.quad_grand_total_amount
                        : tour.triple_grand_total_amount !== null
                          ? tour.triple_grand_total_amount
                          : tour.double_grand_total_amount}
                          </div>
												</div>
												
											</div>

											<div class="flts-flex-end">
												<div class="row align-items-center justify-content-end gx-2">
													<div class="col-auto text-start text-md-end">
														<div class="text-md text-dark fw-medium">{tour.no_of_pax_days} Seats Available</div>
													</div>
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
             
              </div>
            ))}
          </div>
        )}
        {/* {packagesData.tours.length > 0 && (
          <OwlCarousel className="owl-theme custom-owl-carousel" items={itemsToShow} margin={8} nav>
            {packagesData.tours.flat().filter(
              (tour) => tour.categories === packagesData.categories[activeItem].id.toString()
            ).map((tour, index) => (
              <div key={index} className="item recommended">
                <div class="card-wrap ms-4 cursor-pointer" onClick={(event) => fetchViewDetail(event, tour.id, tour.title)}>
                  <div class="con-img-wrap m-auto">
                    <div className='card-image'>
                      <img src={url + '/public/uploads/package_imgs/' + tour.tour_banner_image} alt={tour.title} class="img-fluid mx-auto d-block" />
                    </div>
                   
                  </div>
                  <div class="con-wrap mt-4">
                    <h2 class="fs-6 mt-4 fw-bold text-truncate">{tour.title}</h2>
                    <div className="d-flex justify-content-between">
                      <div className="card-text">
                        <FontAwesomeIcon icon={faPlaneDeparture} />{' '}
                        {moment(tour.start_date).format('DD-MM-YYYY')}
                      </div>
                      <div className="card-text">
                        <FontAwesomeIcon icon={faPlaneArrival} />{' '}
                        {moment(tour.end_date).format('DD-MM-YYYY')}
                      </div>
                    </div>
                    <div class="d-flex bottom mb-2">
                      <div class="rating-cover">
                        <span class="p-1 small rounded-1 bg-warning theme-text-white">{tour.no_of_pax_days} Seats Available</span>
                        <span class="small ms-2">{tour.time_duration} Nights</span>
                      </div>
                    </div>
                    <p class="mb-0 theme-text-accent-one">Starting from  {tour.currency_symbol}{' '}
                      {tour.quad_grand_total_amount !== null
                        ? tour.quad_grand_total_amount
                        : tour.triple_grand_total_amount !== null
                          ? tour.triple_grand_total_amount
                          : tour.double_grand_total_amount}</p>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        )} */}
      </div>
    </>
  );
}

export default Packages;
