import React, { useState, useEffect } from 'react'
import logo from './logo.png'
import Axios from 'axios'
import ReactDOM from 'react-dom'
import { json, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CurrencyConverter } from '../GlobalData/GlobalData'
import { Hotelapitoken, ApiEndPoint } from '../GlobalData/GlobalData'
import { CurrencyRates, AllCurrencyRates } from '../../Redux/Actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faSignIn,
  faBars,
  faTimes,
  faChevronDown,
  faCross,
  faClose,
  faAngleRight,
  faArrowDown,
  faAngleDown,
  faMosque,
  faKaaba,
  faAnglesRight
} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';
import LanguageSwitcher from '../Activity/TranslationTrigger';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next';
function Navbar() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigation = useNavigate()
  var token = Hotelapitoken()
  var endpoint = ApiEndPoint()
  const [isHovered, setIsHovered] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [userCountry, setUserCountry] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [dropdownCat, setDropdownCat] = useState([])
  const [isDropdownOpen3, setDropdownOpen3] = useState(false)
  const [isDropdownOpen2, setDropdownOpen] = useState(false)

  const [selectedCurrency, setSelectedCurrency] = useState('GBP')

  // This function put query that helps to
  // change the language

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen2)
  }
  const toggleDropdown1 = () => {
    setDropdownOpen3(!isDropdownOpen3)
  }


  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen)
  }
  const buttonIconClass = isMobileNavOpen
    ? 'navbar-toggler-icon-cross'
    : 'navbar-toggler-icon'
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  useEffect(() => {
    GetCatagories();
    fetchData();
    AllCurrency();
    const storedCurrency = localStorage.getItem('selectedCurrency')
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency);
      CurrencyCalucaltion(storedCurrency);
    }
    else {
      dispatch(CurrencyRates(undefined))
    }
  }, []);

  const SearchValue = e => {
    var filteredList = countryList.filter(item =>
      item.currency.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setUserCountry(filteredList)
    setSearchValue(e.target.value)
  };

  async function fetchData() {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    try {
      const response = await Axios.get(
        endpoint + '/api/countries/fetch',
        headers
      )
      setCountryList(response.data.countries)
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
    }
  }
  const handleCurrencyDivClick = (index, currency) => {
    toggleModal();
    setActiveIndex(index);
    setSelectedCurrency(currency);
    CurrencyCalucaltion(currency);
    localStorage.setItem('selectedCurrency', currency)

    // Perform other calculations or actions here
  }

  const CurrencyCalucaltion = c => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + c, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        response.data.selectedcurrency = c;
        dispatch(CurrencyRates(response.data));

      })
      .catch(error => {
        if (localStorage.getItem('selectedCurrency')) {
          localStorage.removeItem('selectedCurrency');
        }
        dispatch(CurrencyRates(undefined));
        // Handle errors here
        console.error(error)
      })
  }

  const AllCurrency = () => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/GBP', // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    };

    Axios.request(config)
      .then(response => {
        dispatch(AllCurrencyRates(response.data))
      })
      .catch(error => {
        dispatch(AllCurrencyRates(undefined))
        // Handle errors here
        console.error(error)
      })
  }

  const GetCatagories = async () => {
    var data = {
      token: token
    };
    try {
      const response = await Axios.post(
        endpoint + '/api/get_all_catigories_list_apis_new',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      sessionStorage.setItem('Catogories', JSON.stringify(response.data.categories));
      setDropdownCat(response.data.categories);

    } catch (error) {
      console.error('Error:', error);
    }
  };
  const FindPackageDetail = id => {
    toggleDropdown();
    navigation('/umrah_packages', { state: id })
  };
  const languages = [
    { value: "en", text: "English" },
    { value: "ar-SA", text: "Arabic" },
  ];

  return (
    <>
      <div class="head-wrapper">
        <header  class="header theme-bg-white">
          <div class="container px-2">

            <nav class={`${isMobileNavOpen ? 'mobile-navnbarn' : 'navbar'} navbar-expand-lg py-4 py-lg-0 px-0`}>
              <img src={logo} alt="Brand Logo" width='320' title="Brand Logo" class="img-fluid nav-logo py-3" />
              <button onClick={toggleMobileNav} style={{ fontSize: '2em' }} class="navbar-toggler text-white px-1 btn rounded-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <FontAwesomeIcon icon={faBars} />
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto page-menu" id="nav">
                  <li class="nav-item  nav-link scrollto fw-bold"><NavLink className={({ isActive }) => `nav-link pe-2 ${isActive ? "active" : ""}`} to='/'>Home</NavLink>
                  </li>
                  <li class="nav-item dropdown nav-link scrollto fw-bold">
                    <a onClick={toggleDropdown} class="nav-link  dropdown-toggle pe-2" >Packages</a>
                    {isDropdownOpen2 && (
                      <ul>
                        {dropdownCat.map((item, index) => (
                          <li key={index} className='mb-3'>
                            <h6
                              onClick={() => FindPackageDetail(item.id)}
                              className='dropdown-nav-link '
                            >
                              {t(`${item.title}`)}
                            </h6>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                  <li class="nav-item dropdown nav-link scrollto fw-bold">

                    <a onClick={toggleDropdown1} class="nav-link dropdown-toggle  pe-2" ><FontAwesomeIcon className='fa-bold fw-bold' icon={faKaaba} /> Hajj 2025</a>
                    {isDropdownOpen3 && (
                      <ul>
                        <li className='mb-3'>
                          <NavLink className={({ isActive }) => `nav-link  pe-2 ${isActive ? "active" : ""}`} to='/hajj'> <h6
                            className='dropdown-nav-link '
                          >
                            {t('Interest')}
                          </h6></NavLink>
                        </li>

                        <li className="mb-3 dropdown-submenu position-relative" onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}>

                          <h6 className="dropdown-nav-link dropdown-item">
                            {t('Support Channels')}{' '}
                            <FontAwesomeIcon icon={faAngleRight} />
                          </h6>

                          {isHovered && (
                            <ul className="dropdown-menu" style={{ display: 'none' }}>
                              <li>
                                <a href='https://chat.whatsapp.com/IfJtMNZQXerDgcltof7HGy' className="dropdown-item" to="/hajj/faq">
                                  <svg
                                    fill="#279b3e"
                                    height="25px"
                                    width="25px"
                                    viewBox="0 0 308 308"
                                    stroke="#279b3e"
                                  >
                                    <g>
                                      <path
                                        d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
        c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
        c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
        c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
        c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
        c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
        c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
        c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
        c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
        C233.168,179.508,230.845,178.393,227.904,176.981z"
                                      />
                                      <path
                                        d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
        c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
        c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
        M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
        l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
        c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
        C276.546,215.678,222.799,268.994,156.734,268.994z"
                                      />
                                    </g>
                                  </svg>

                                  {' '}Whatsapp Link
                                </a>
                              </li>
                              <li>
                                <a href='https://t.me/+tF5MQ2KCCCU1Y2Zk' className="dropdown-item" target='blank'>
                                  <svg viewBox="0 0 24 24" fill="none" stroke="#2180ba" height="25px"
                                    width="25px">
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M23.1117 4.49449C23.4296 2.94472 21.9074 1.65683 20.4317 2.227L2.3425 9.21601C0.694517 9.85273 0.621087 12.1572 2.22518 12.8975L6.1645 14.7157L8.03849 21.2746C8.13583 21.6153 8.40618 21.8791 8.74917 21.968C9.09216 22.0568 9.45658 21.9576 9.70712 21.707L12.5938 18.8203L16.6375 21.8531C17.8113 22.7334 19.5019 22.0922 19.7967 20.6549L23.1117 4.49449ZM3.0633 11.0816L21.1525 4.0926L17.8375 20.2531L13.1 16.6999C12.7019 16.4013 12.1448 16.4409 11.7929 16.7928L10.5565 18.0292L10.928 15.9861L18.2071 8.70703C18.5614 8.35278 18.5988 7.79106 18.2947 7.39293C17.9906 6.99479 17.4389 6.88312 17.0039 7.13168L6.95124 12.876L3.0633 11.0816ZM8.17695 14.4791L8.78333 16.6015L9.01614 15.321C9.05253 15.1209 9.14908 14.9366 9.29291 14.7928L11.5128 12.573L8.17695 14.4791Z"
                                      fill="#2d7fbe"
                                    />
                                  </svg>{' '}
                                  Telegram Link
                                </a>
                              </li>
                            </ul>
                          )}
                        </li>

                      </ul>
                    )}
                  </li>
                  <li class="nav-item fw-bold nav-link scrollto "><NavLink className={({ isActive }) => `nav-link  pe-2 ${isActive ? "active" : ""}`}  to='/about-us'><a  >About us</a></NavLink></li>
                  <li class="nav-item fw-bold nav-link scrollto "><NavLink className={({ isActive }) => `nav-link  pe-2 ${isActive ? "active" : ""}`} to='/contact-us'><a  >Contact us</a></NavLink></li>

                </ul>
                <div class=" menu-droup">
                  <ul class="navbar-nav page-menu justify-content-center">
                    <li class="nav-item dropdown mt-1">
                      {/* <a href="#" class="nav-link mein-link dropdown-toggle" id="navbarDropdown1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="bi bi-globe me-2"></i>English</a>
                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown1">
                                            <li><a class="dropdown-item" href="#">English</a></li>
                                            <li><a class="dropdown-item" href="#">Arabic</a>
                                            </li>
                                        </ul> */}
                      <I18nextProvider i18n={i18n}>
                        <LanguageSwitcher language="arabic" />
                      </I18nextProvider>
                    </li>
                    <li class="nav-item dropdown mt-1" onClick={toggleModal}>
                      <p class="nav-link mein-link text-black nav-currency" aria-expanded="false">{selectedCurrency}</p>

                    </li>
                    {/* <li class="">
                    <a href="tel:01217772522"> <p class="nav-link  mein-link text-black"  >0121 777 2522</p></a>

                    </li> */}

                  </ul>
                  <div className='d-flex'>
                    <a href="tel:01217772522" className='text-center mt-1 me-2'> <p class="nav-link text-white  mein-link"  >
                      <svg
                        fill="#178229"
                        height="18px"
                        width="18px"
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="#178229"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <path d="M422.28 220.416h455.803c16.962 0 30.72 13.758 30.72 30.72v624.128c0 16.962-13.758 30.72-30.72 30.72h-734.72c-16.968 0-30.72-13.754-30.72-30.72V251.136c0-16.966 13.752-30.72 30.72-30.72h54.272v-40.96h-54.272c-39.591 0-71.68 32.094-71.68 71.68v624.128c0 39.586 32.089 71.68 71.68 71.68h734.72c39.583 0 71.68-32.097 71.68-71.68V251.136c0-39.583-32.097-71.68-71.68-71.68H422.28v40.96z"></path>
                          <path d="M370.944 802.438c16.962 0 30.72-13.758 30.72-30.72V143.361c0-16.968-13.754-30.72-30.72-30.72h-120.32c-16.966 0-30.72 13.752-30.72 30.72v628.357c0 16.962 13.758 30.72 30.72 30.72h120.32zm0 40.96h-120.32c-39.583 0-71.68-32.097-71.68-71.68V143.361c0-39.591 32.094-71.68 71.68-71.68h120.32c39.586 0 71.68 32.089 71.68 71.68v628.357c0 39.583-32.097 71.68-71.68 71.68zm422.391-458.374c5.657 0 10.24-4.583 10.24-10.24v-41.472c0-5.657-4.583-10.24-10.24-10.24H558.184a10.238 10.238 0 00-10.24 10.24v41.472c0 5.657 4.583 10.24 10.24 10.24h235.151zm0 40.96H558.184c-28.278 0-51.2-22.922-51.2-51.2v-41.472c0-28.278 22.922-51.2 51.2-51.2h235.151c28.278 0 51.2 22.922 51.2 51.2v41.472c0 28.278-22.922 51.2-51.2 51.2zm-194.889 81.92c0 14.141-11.459 25.6-25.6 25.6s-25.6-11.459-25.6-25.6c0-14.141 11.459-25.6 25.6-25.6s25.6 11.459 25.6 25.6zm102.4 0c0 14.141-11.459 25.6-25.6 25.6s-25.6-11.459-25.6-25.6c0-14.141 11.459-25.6 25.6-25.6s25.6 11.459 25.6 25.6zm103.424 0c0 14.141-11.459 25.6-25.6 25.6s-25.6-11.459-25.6-25.6c0-14.141 11.459-25.6 25.6-25.6s25.6 11.459 25.6 25.6zM598.446 612.352c0 14.141-11.459 25.6-25.6 25.6s-25.6-11.459-25.6-25.6c0-14.141 11.459-25.6 25.6-25.6s25.6 11.459 25.6 25.6zm102.4 0c0 14.141-11.459 25.6-25.6 25.6s-25.6-11.459-25.6-25.6c0-14.141 11.459-25.6 25.6-25.6s25.6 11.459 25.6 25.6zm103.424 0c0 14.141-11.459 25.6-25.6 25.6s-25.6-11.459-25.6-25.6c0-14.141 11.459-25.6 25.6-25.6s25.6 11.459 25.6 25.6zm-205.824 102.4c0 14.141-11.459 25.6-25.6 25.6s-25.6-11.459-25.6-25.6c0-14.141 11.459-25.6 25.6-25.6s25.6 11.459 25.6 25.6zm102.4 0c0 14.141-11.459 25.6-25.6 25.6s-25.6-11.459-25.6-25.6c0-14.141 11.459-25.6 25.6-25.6s25.6 11.459 25.6 25.6zm103.424 0c0 14.141-11.459 25.6-25.6 25.6s-25.6-11.459-25.6-25.6c0-14.141 11.459-25.6 25.6-25.6s25.6 11.459 25.6 25.6z"></path>
                        </g>
                      </svg>{' '}
                      0121 777 2522</p></a>
                    <a href="https://wa.me/+447309803307" className='text-center mt-1'> <p class="nav-link text-white mein-link"  >
                      <svg
                        fill="#279b3e"
                        height="18px"
                        width="18px"
                        viewBox="0 0 308 308"
                        stroke="#279b3e"
                      >
                        <g>
                          <path
                            d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
        c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
        c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
        c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
        c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
        c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
        c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
        c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
        c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
        C233.168,179.508,230.845,178.393,227.904,176.981z"
                          />
                          <path
                            d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
        c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
        c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
        M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
        l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
        c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
        C276.546,215.678,222.799,268.994,156.734,268.994z"
                          />
                        </g>
                      </svg>{' '}
                      +44 7309 803307
                    </p></a>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </header>
      </div>
      {/* <header>
        <div className='navbar-top'>
          <div className='row'>
            <div className='col-lg-4 col-md-4 navbar-img col-sm-12'>
              <img src={logo} alt='Logo' height='60' />
            </div>
            <div className='col-lg-8 col-md-8 col-sm-12'>
              <div className='navbar-top-right '>
                <div className='d-flex  '>
                  <FontAwesomeIcon icon={faPhone} />
                  <h6>0121 777 2522</h6>
                </div>
                <div className='d-flex  '>
                  <button
                    onClick={toggleModal}
                    className='btn btn-primary currency-btn'
                  >
                    {selectedCurrency}
                  </button>
                  <I18nextProvider i18n={i18n}>
                    <LanguageSwitcher language="arabic" />
                  </I18nextProvider>
                </div>
              
              </div>
            </div>
          </div>
        </div>

        <nav
          id='navbar'
          className={
            'navbar-expand-lg pb-2  navbar-dark   ' +
            (isMobileNavOpen ? 'navbar-mobile' : 'navbar')
          }
        >
          <button
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            className='navbar-toggler'
          >
            {isMobileNavOpen ? (
              <span
                style={{ color: 'white' }}
                onClick={toggleMobileNav}
                className='mobile-nav-toggle '
              >
                {' '}
                <FontAwesomeIcon icon={faClose} />
              </span>
            ) : (
              <span
                onClick={toggleMobileNav}
                className='mobile-nav-toggle navbar-toggler-icon'
              ></span>
            )}
          </button>
          <ul className='navbar-nav navbar-link  ml-auto'>
            <li className='nav-item nav-link scrollto  active'>
              <NavLink className='nav-link' to='/'>
                {t('Home')}
              </NavLink>
            </li>
            <li className='nav-item ps-3 dropdown nav-link scrollto'>
              <h5 onClick={toggleDropdown} className='nav-link'>
                {t('Packages')}{' '}
                <FontAwesomeIcon onClick={toggleDropdown} icon={faAngleDown} />
              </h5>
              {isDropdownOpen2 && (
                <ul>
                  {dropdownCat.map((item, index) => (
                    <li key={index} className='mb-3'>
                      <h6
                        onClick={() => FindPackageDetail(item.id)}
                        className='dropdown-nav-link '
                      >
                        {t(`${item.title}`)}
                      </h6>
                    </li>
                  ))}
                </ul>
              )}
            </li>
           
            <li className='nav-item ps-3 dropdown nav-link scrollto' >
              <h5 onClick={toggleDropdown1} className='nav-link'>
                <FontAwesomeIcon className='fa-bold fw-bold' icon={faKaaba} />{' '}
                {t('Hajj 2025')} {' '}
                <FontAwesomeIcon onClick={toggleDropdown1} icon={faAngleDown} />
              </h5>
              {isDropdownOpen3 && (
                <ul>
                  <li className='mb-3'>
                    <NavLink to='/hajj'> <h6
                      className='dropdown-nav-link '
                    >
                      {t('Interest')}
                    </h6></NavLink>
                  </li>

                  <li className="mb-3 dropdown-submenu position-relative" onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}>
                    
                      <h6 className="dropdown-nav-link dropdown-item">
                        {t('Support Channels')}{' '}
                        <FontAwesomeIcon icon={faAngleRight} />
                      </h6>
                  
                    {isHovered && (
                      <ul className="dropdown-menu" style={{ display: 'none' }}>
                        <li>
                        <a href='https://chat.whatsapp.com/IfJtMNZQXerDgcltof7HGy' className="dropdown-item" to="/hajj/faq">
                            <svg
                              fill="#279b3e"
                              height="25px"
                              width="25px"
                              viewBox="0 0 308 308"
                              stroke="#279b3e"
                            >
                              <g>
                                <path
                                  d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
        c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
        c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
        c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
        c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
        c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
        c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
        c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
        c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
        C233.168,179.508,230.845,178.393,227.904,176.981z"
                                />
                                <path
                                  d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
        c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
        c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
        M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
        l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
        c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
        C276.546,215.678,222.799,268.994,156.734,268.994z"
                                />
                              </g>
                            </svg>

                            {' '}Whatsapp
                          </a>
                        </li>
                        <li>
                        <a href='https://t.me/+tF5MQ2KCCCU1Y2Zk' className="dropdown-item" target='blank'> 
                            <svg viewBox="0 0 24 24" fill="none" stroke="#2180ba" height="25px"
                              width="25px">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M23.1117 4.49449C23.4296 2.94472 21.9074 1.65683 20.4317 2.227L2.3425 9.21601C0.694517 9.85273 0.621087 12.1572 2.22518 12.8975L6.1645 14.7157L8.03849 21.2746C8.13583 21.6153 8.40618 21.8791 8.74917 21.968C9.09216 22.0568 9.45658 21.9576 9.70712 21.707L12.5938 18.8203L16.6375 21.8531C17.8113 22.7334 19.5019 22.0922 19.7967 20.6549L23.1117 4.49449ZM3.0633 11.0816L21.1525 4.0926L17.8375 20.2531L13.1 16.6999C12.7019 16.4013 12.1448 16.4409 11.7929 16.7928L10.5565 18.0292L10.928 15.9861L18.2071 8.70703C18.5614 8.35278 18.5988 7.79106 18.2947 7.39293C17.9906 6.99479 17.4389 6.88312 17.0039 7.13168L6.95124 12.876L3.0633 11.0816ZM8.17695 14.4791L8.78333 16.6015L9.01614 15.321C9.05253 15.1209 9.14908 14.9366 9.29291 14.7928L11.5128 12.573L8.17695 14.4791Z"
                                fill="#2d7fbe"
                              />
                            </svg>{' '}
                            Telegram
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                
                </ul>
              )}
            </li>
           
            <li className='nav-item nav-link scrollto'>
              <NavLink className='nav-link' to='/about-us'>
                {t('About Us')}
              </NavLink>
              <span class=' submenu-toggle'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </li>
            <li className='nav-item nav-link scrollto'>
              <NavLink className='nav-link' to='/contact-us'>
                {t('Contact Us')}
              </NavLink>
              <span className=' submenu-toggle'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </li>
          </ul>
        </nav>
      </header> */}

      {/*********** Cuurency  Model ****************/}
      <Modal
        style={{ maxWidth: '90%' }}
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>Select Your Currency</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <p>
              Where applicable, prices will be converted to—and shown in—the
              currency you select. The currency you pay in may differ based on
              your reservation, and a service fee may also apply.
            </p>
            <div className='border-line mt-2 mb-2'></div>
            {/* <div className='row mt-2'>
         <h5>Suggested for you</h5>
       </div> */}
            <div className='row'>
              <div className='d-flex justify-content-between'>
                <h5>All Currencies</h5>
                <input
                  type='text'
                  value={searchValue}
                  onChange={SearchValue}
                  placeholder='Search'
                />
              </div>

              {searchValue === ''
                ? // Render the full list when there is no search input
                countryList.map((item, index) => (
                  <div key={index} className='col-md-4 mt-2 currency-modal'>
                    <div
                      className={` ${index === activeIndex ? 'currency-div' : ''
                        }`}
                      onClick={() =>
                        handleCurrencyDivClick(index, item.currency)
                      }
                    >
                      <p className='currency-item'>
                        {item.name} - {item.currency}
                      </p>
                    </div>
                  </div>
                ))
                : // Render the filtered list when there is a search input
                userCountry.map((item, index) => (
                  <div key={index} className='col-md-4 mt-2 currency-modal'>
                    <div
                      className={` ${index === activeIndex ? 'currency-div' : ''
                        }`}
                      onClick={() =>
                        handleCurrencyDivClick(index, item.currency)
                      }
                    >
                      <p className='currency-item'>
                        {item.name} - {item.currency}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Navbar
