import React, { useEffect, useState } from 'react'
import img1 from '../../Images/Carousal/1.jpg'
import '../../Components/Carousal/style.css'
import img2 from '../../Images/Carousal/2.jpg'
import img3 from '../../Images/Carousal/3.jpg'
import cutomer from '../../Images/alhijaz.png'
import hajj from '../../Images/HajjTravel.png'
import logo1 from '../../Images/Logo/alhijaz2.png'
import logo2 from '../../Images/Logo/mcdc.png'
import logo3 from '../../Images/Logo/nusk.png'
import logo4 from '../../Images/Logo/saudia.png'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import img4 from '../../Images/Carousal/4.jpg'
import Carousel from 'react-bootstrap/Carousel'
import OwlCarousel from 'react-owl-carousel'
import SearchBar from '../../Components/SearchBar/Searchbar'
import Packages from '../../Components/Packages/Packages'
import agentpic from '../../Images/Home/agentpic.png'
import adimage from '../../Images/Hajj/home25.jpg'
import Hotels from '../../Components/Hotel/Hotels'
import whatsappcumenity from '../../Images/whatsappcuminityBtn.png'
import telegramcum from '../../Images/telegramcomunityBtn.png'
import MorePackages from '../../Components/MorePackages/MorePackages'
import Layout from '../../Components/Layout/Layout'
import Loader from '../../Components/Loading/Loader'
import Helmet from 'react-helmet'
import wow from 'wowjs'
import { NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock,
  faEnvelope,
  faHeadphones,
  faMoneyBill,
  faPhone,
  faPlay,
  faTimes,
  faTimesCircle,
  faVideo
} from '@fortawesome/free-solid-svg-icons'
function Home () {
  const [isLoading, setIsLoading] = useState(true)
  const [bottomAdd, setShowbottomAdd] = useState(true)
  const [modalOpen, setModalOpen] = useState(true)
  const [isVideoVisible, setIsVideoVisible] = useState(false)
  const [videoSrc, setVideoSrc] = useState('')

  // Video URL (YouTube embed URL with autoplay)
  // const videoURL = "https://www.youtube.com/embed/r44RKWyfcFw?autoplay=1";
  const videoURL = 'https://www.youtube.com/embed/2GfE30JbCSQ'
  const openVideoOverlay = () => {
    setIsVideoVisible(true)
    setVideoSrc(videoURL) // Set video URL with autoplay
  }

  const closeVideoOverlay = () => {
    setIsVideoVisible(false)
    setVideoSrc('') // Stop video when closing the overlay
  }
  const navigate = useNavigate()
  useEffect(() => {
    new wow.WOW().init()

    const fetchData = async () => {
      await new Promise(resolve => setTimeout(resolve, 2000))
      setIsLoading(false)
    }

    fetchData()
  }, [])

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const hideadd = () => {
    setShowbottomAdd(false)
  }
  const gotoHajjPage = () => {
    navigate('/hajj')
  }
  return (
    <>
      <Helmet>
        <title>AlHijaz Tours</title>
      </Helmet>
      {isLoading && <Loader />}
      <Layout>
        <div className='overlay-container'>
          <Carousel className='carousel-container'>
            <Carousel.Item>
              <img className='d-block w-100' src={img1} alt='First slide' />
            </Carousel.Item>
            <Carousel.Item>
              <img className='d-block w-100' src={img2} alt='First slide' />
            </Carousel.Item>
            <Carousel.Item>
              <img className='d-block w-100' src={img3} alt='First slide' />
            </Carousel.Item>
            <Carousel.Item>
              <img className='d-block w-100' src={img4} alt='First slide' />
            </Carousel.Item>
          </Carousel>
          <SearchBar />
        </div>
        <div class='f_cta_icons'>
          <div
            class='chaty-channel Phone-channel'
            id='Phone-0-channel'
            data-id='Phone-0'
            data-widget='0'
            data-channel='Phone'
          >
            <a href='tel:01217772522'>
              <span class='chaty-svg'>
                <svg
                  width='39'
                  height='39'
                  viewBox='0 0 39 39'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    class='color-element'
                    cx='19.4395'
                    cy='19.4395'
                    r='19.4395'
                    fill='#03E78B'
                  ></circle>
                  <path
                    d='M19.3929 14.9176C17.752 14.7684 16.2602 14.3209 14.7684 13.7242C14.0226 13.4259 13.1275 13.7242 12.8292 14.4701L11.7849 16.2602C8.65222 14.6193 6.11623 11.9341 4.47529 8.95057L6.41458 7.90634C7.16046 7.60799 7.45881 6.71293 7.16046 5.96705C6.56375 4.47529 6.11623 2.83435 5.96705 1.34259C5.96705 0.596704 5.22117 0 4.47529 0H0.745882C0.298353 0 5.69062e-07 0.298352 5.69062e-07 0.745881C5.69062e-07 3.72941 0.596704 6.71293 1.93929 9.3981C3.87858 13.575 7.30964 16.8569 11.3374 18.7962C14.0226 20.1388 17.0061 20.7355 19.9896 20.7355C20.4371 20.7355 20.7355 20.4371 20.7355 19.9896V16.4094C20.7355 15.5143 20.1388 14.9176 19.3929 14.9176Z'
                    transform='translate(9.07179 9.07178)'
                    fill='white'
                  ></path>
                </svg>
              </span>
            </a>
          </div>
          <div class='chaty-channel Whatsapp-channel' id='Whatsapp-0-channel'>
            <a
              href='https://wa.me/+447309803307?text=Hi, I would like to contact you from Alhijaz Tours.'
              target='_blank'
              class='chaty-tooltip pos-right'
            >
              <span class='chaty-svg'>
                <svg
                  width='39'
                  height='39'
                  viewBox='0 0 39 39'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    class='color-element'
                    cx='19.4395'
                    cy='19.4395'
                    r='19.4395'
                    fill='#49E670'
                  ></circle>
                  <path
                    d='M12.9821 10.1115C12.7029 10.7767 11.5862 11.442 10.7486 11.575C10.1902 11.7081 9.35269 11.8411 6.84003 10.7767C3.48981 9.44628 1.39593 6.25317 1.25634 6.12012C1.11674 5.85403 2.13001e-06 4.39053 2.13001e-06 2.92702C2.13001e-06 1.46351 0.83755 0.665231 1.11673 0.399139C1.39592 0.133046 1.8147 1.01506e-06 2.23348 1.01506e-06C2.37307 1.01506e-06 2.51267 1.01506e-06 2.65226 1.01506e-06C2.93144 1.01506e-06 3.21063 -2.02219e-06 3.35022 0.532183C3.62941 1.19741 4.32736 2.66092 4.32736 2.79397C4.46696 2.92702 4.46696 3.19311 4.32736 3.32616C4.18777 3.59225 4.18777 3.59224 3.90858 3.85834C3.76899 3.99138 3.6294 4.12443 3.48981 4.39052C3.35022 4.52357 3.21063 4.78966 3.35022 5.05576C3.48981 5.32185 4.18777 6.38622 5.16491 7.18449C6.42125 8.24886 7.39839 8.51496 7.81717 8.78105C8.09636 8.91409 8.37554 8.9141 8.65472 8.648C8.93391 8.38191 9.21309 7.98277 9.49228 7.58363C9.77146 7.31754 10.0507 7.1845 10.3298 7.31754C10.609 7.45059 12.2841 8.11582 12.5633 8.38191C12.8425 8.51496 13.1217 8.648 13.1217 8.78105C13.1217 8.78105 13.1217 9.44628 12.9821 10.1115Z'
                    transform='translate(12.9597 12.9597)'
                    fill='#FAFAFA'
                  ></path>
                  <path
                    d='M0.196998 23.295L0.131434 23.4862L0.323216 23.4223L5.52771 21.6875C7.4273 22.8471 9.47325 23.4274 11.6637 23.4274C18.134 23.4274 23.4274 18.134 23.4274 11.6637C23.4274 5.19344 18.134 -0.1 11.6637 -0.1C5.19344 -0.1 -0.1 5.19344 -0.1 11.6637C-0.1 13.9996 0.624492 16.3352 1.93021 18.2398L0.196998 23.295ZM5.87658 19.8847L5.84025 19.8665L5.80154 19.8788L2.78138 20.8398L3.73978 17.9646L3.75932 17.906L3.71562 17.8623L3.43104 17.5777C2.27704 15.8437 1.55796 13.8245 1.55796 11.6637C1.55796 6.03288 6.03288 1.55796 11.6637 1.55796C17.2945 1.55796 21.7695 6.03288 21.7695 11.6637C21.7695 17.2945 17.2945 21.7695 11.6637 21.7695C9.64222 21.7695 7.76778 21.1921 6.18227 20.039L6.17557 20.0342L6.16817 20.0305L5.87658 19.8847Z'
                    transform='translate(7.7758 7.77582)'
                    fill='white'
                    stroke='white'
                    stroke-width='0.2'
                  ></path>
                </svg>
              </span>
            </a>
          </div>
          {/* <div class='join'>
            <a
              href='https://chat.whatsapp.com/IfJtMNZQXerDgcltof7HGy'
              target='_blank'
            >
              <img
                src={whatsappcumenity}
                style={{ width: '45px' }}
              />
            </a>
          </div> */}
          
          {/* <div class='myDIV'>
            <a href='https://t.me/+tF5MQ2KCCCU1Y2Zk' target='_blank'>
              <img
                src={telegramcum}
                style={{ width: '45px', marginTop: '7px' }}
              />
            </a>
          </div> */}
        </div>
        <Packages />
        <div className='container'>
          <div className='row section_margin'>
            <div className='col-md-6'>
              <div class='elementor-widget-container'>
                <img
                  loading='lazy'
                  decoding='async'
                  src={hajj}
                  class='attachment-full size-full wp-image-10832'
                  alt=''
                />{' '}
              </div>
            </div>
            <div className='col-md-6 d-flex justify-content-center align-items-center'>
              <div
                class='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-56ab6f6'
                data-id='56ab6f6'
                data-element_type='column'
              >
                <div class='elementor-widget-wrap elementor-element-populated'>
                  <div
                    class='elementor-element elementor-element-d54dc7e elementor-widget elementor-widget-elementskit-heading'
                    data-id='d54dc7e'
                    data-element_type='widget'
                    data-widget_type='elementskit-heading.default'
                  >
                    <div class='elementor-widget-container'>
                      <div class='ekit-wid-con'>
                        <div class='ekit-heading elementskit-section-title-wraper text_left   ekit_heading_tablet-   ekit_heading_mobile-'>
                          {/* <h2 class='ekit-heading--title elementskit-section-title '>
                          Explore the wonders of{' '}
                          <span>
                            <span>Saudia Arabia,</span>
                          </span>{' '}
                          with a more comfortable &amp; enriching
                        </h2> */}
                          <h3 class='ekit-heading--subtitle mt-4 elementskit-section-subtitle  '>
                            Hajj 2025
                          </h3>{' '}
                          <div class='ekit-heading__description mt-4'>
                            <p>
                              We are pleased to announce that{' '}
                              <span style={{ color: '#bca130' }}>
                                <strong>Al Hijaz Tours</strong>
                              </span>{' '}
                              partnered with the highly reputable Saudi company
                              to successfully facilitate Hajj 1444H/2025 for
                              pilgrims from the UK in line with new guidelines
                              and processes from the Ministry of Hajj and Nusuk
                              Portal.
                            </p>
                          </div>
                        </div>
                      </div>{' '}
                    </div>
                  </div>
                  <div
                    class='elementor-element mt-4 elementor-element-f78290e elementor-widget elementor-widget-image'
                    data-id='f78290e'
                    data-element_type='widget'
                    data-widget_type='image.default'
                  >
                    <div class='elementor-widget-container text-center'>
                      <img
                        loading='lazy'
                        decoding='async'
                        height='50'
                        class='attachment-large size-large wp-image-10937'
                        alt=''
                        src={logo3}
                      />{' '}
                      <img
                        loading='lazy'
                        decoding='async'
                        height='90'
                        class='attachment-large size-large wp-image-10937'
                        alt=''
                        src={logo1}
                      />{' '}
                      <img
                        loading='lazy'
                        decoding='async'
                        height='100'
                        class='attachment-large size-large wp-image-10937'
                        alt=''
                        src={logo2}
                      />{' '}
                      <img
                        loading='lazy'
                        decoding='async'
                        height='100'
                        class='attachment-large size-large wp-image-10937'
                        alt=''
                        src={logo4}
                      />{' '}
                    </div>
                  </div>
                  <div
                                style={{ flexWrap: 'wrap' }}
                                className='d-flex  hajj-links-2 justify-content-center align-items-center'
                              >
                                <div>
                                  <a
                                    class='nav-link '
                                    href='https://chat.whatsapp.com/IfJtMNZQXerDgcltof7HGy'
                                    target='blank'
                                  >
                                    <svg
                                      fill='#279b3e'
                                      height='18px'
                                      width='18px'
                                      viewBox='0 0 308 308'
                                      stroke='#279b3e'
                                    >
                                      <g>
                                        <path
                                          d='M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
                          c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
                          c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
                          c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
                          c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
                          c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
                          c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
                          c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
                          c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
                          C233.168,179.508,230.845,178.393,227.904,176.981z'
                                        />
                                        <path
                                          d='M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
                          c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
                          c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
                          M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
                          l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
                          c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
                          C276.546,215.678,222.799,268.994,156.734,268.994z'
                                        />
                                      </g>
                                    </svg>{' '}
                                    JOIN HAJJ WHATSAPP GROUP
                                  </a>
                                </div>
                                <div>
                                  <a
                                    class='nav-link '
                                    href='https://t.me/+tF5MQ2KCCCU1Y2Zk'
                                    target='_blank'
                                  >
                                    <svg
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      stroke='#2180ba'
                                      height='18px'
                                      width='18px'
                                    >
                                      <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M23.1117 4.49449C23.4296 2.94472 21.9074 1.65683 20.4317 2.227L2.3425 9.21601C0.694517 9.85273 0.621087 12.1572 2.22518 12.8975L6.1645 14.7157L8.03849 21.2746C8.13583 21.6153 8.40618 21.8791 8.74917 21.968C9.09216 22.0568 9.45658 21.9576 9.70712 21.707L12.5938 18.8203L16.6375 21.8531C17.8113 22.7334 19.5019 22.0922 19.7967 20.6549L23.1117 4.49449ZM3.0633 11.0816L21.1525 4.0926L17.8375 20.2531L13.1 16.6999C12.7019 16.4013 12.1448 16.4409 11.7929 16.7928L10.5565 18.0292L10.928 15.9861L18.2071 8.70703C18.5614 8.35278 18.5988 7.79106 18.2947 7.39293C17.9906 6.99479 17.4389 6.88312 17.0039 7.13168L6.95124 12.876L3.0633 11.0816ZM8.17695 14.4791L8.78333 16.6015L9.01614 15.321C9.05253 15.1209 9.14908 14.9366 9.29291 14.7928L11.5128 12.573L8.17695 14.4791Z'
                                        fill='#2d7fbe'
                                      />
                                    </svg>{' '}
                                    JOIN HAJJ TELEGRAM CHANNEL
                                  </a>
                                </div>
                              </div>
                  <div className='mt-2'>
                    <NavLink to='/hajj'>
                      <button className='btn search-btn1 mt-3'>
                        {' '}
                        Reegister your interest
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="section-title mt-5">
            <h2 className="wow animate__animated animate__fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.3s">
                <span>HAJJ IN A GLIMPSE </span> 
              </h2>
          </div>
          <div className='section_margin py-4'>
          <OwlCarousel
            className='owl-theme'
            loop
            margin={20}
            nav={true}
            dots
            mouseDrag={false}   // Enable mouse drag
             touchDrag={false}
            center={true}
            items={3} // Display 3 items, with the center one being active
            responsive={{
              0: {
                items: 1
              },
              600: {
                items: 2
              },
              1000: {
                items: 3
              }
            }}
          >
            <div className='item'>
              <video
                style={{ height: '13em',width: '30em', transition: 'transform 0.3s ease' }}
                preload='none'
                muted
                loop='false'
                poster='https://cdn.jawar.sa/nusuk/wwwroot/videos/gateway_journey.png'
                controls
              >
                <source
                  src='https://www.youtube.com/embed/2GfE30JbCSQ'
                  type='video/mp4'
                />
              </video>
            </div>

            <div className='item'>
              <video
                style={{height: '13em', width: '30em', transition: 'transform 0.3s ease' }}
                preload='none'
                muted
                loop='false'
                poster='https://cdn.jawar.sa/nusuk/wwwroot/videos/Hajj1445.png'
                controls
              >
                <source
                  src='https://cdn.jawar.sa/nusuk/wwwroot/videos/hajj_1445.mp4'
                  type='video/mp4'
                />
              </video>
            </div>

            <div className='item'>
              <video
                style={{height: '13em', width: '30em', transition: 'transform 0.3s ease' }}
                preload='none'
                muted
                loop='false'
                poster='https://cdn.jawar.sa/nusuk/wwwroot/videos/gateway_journey.png'
                controls
              >
                <source
                  src='https://cdn.jawar.sa/nusuk/wwwroot/videos/gateway_journey.mp4'
                  type='video/mp4'
                />
              </video>
            </div>
          </OwlCarousel>
          </div> */}
        </div>
        <section className='experience mt-4'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='wrap'>
                  <div className='row'>
                    <div className='col-12 position-relative align-self-center'>
                      <h4 className='display-4 theme-text-white mb-0 fw-bold text-center'>
                        Spiritual Travel Experience with
                        <br /> Al Hijaz Tours
                      </h4>
                      <div className='group custom-button'>
                        <div className='d-flex align-items-center'>
                          <button
                            onClick={openVideoOverlay}
                            className='video-icon video-icon2 mr-30 ml-20'
                          >
                            <FontAwesomeIcon icon={faPlay} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Full-Screen Video Overlay */}
          {isVideoVisible && (
            <div className='video-overlay' onClick={closeVideoOverlay}>
              <div className='video-container'>
                <iframe
                  id='youtubePlayer'
                  src={videoSrc}
                  frameborder='0'
                  allow='autoplay'
                  allowFullScreen
                ></iframe>
                <button className='close-button' onClick={closeVideoOverlay}>
                  &times;
                </button>
              </div>
            </div>
          )}
        </section>
        <section>
          <div class='container'>
            <div class='row align-items-center justify-content-between'>
              <div class='col-xl-5 col-lg-5 col-md-6'>
                <div class='position-relative'>
                  <img
                    src={cutomer}
                    class='img-fluid rounded-3 position-relative z-1'
                    alt=''
                  />
                  {/* <div class="position-absolute bottom-0 start-0 z-index-1 mb-4 ms-2">
								<div class="bg-body d-flex d-inline-block rounded-3 position-relative p-3 z-2 shadow-wrap">

									<!-- Avatar group -->
									<div class="me-4">
										<h6 class="fw-normal">Client</h6>
										<!-- Avatar group -->
										<ul class="avatar-group mb-0">
											<li class="avatar avatar-md">
												<img class="avatar-img circle" src="assets/img/team-1.jpg" alt="avatar">
											</li>
											<li class="avatar avatar-md">
												<img class="avatar-img circle" src="assets/img/team-2.jpg" alt="avatar">
											</li>
											<li class="avatar avatar-md">
												<img class="avatar-img circle" src="assets/img/team-3.jpg" alt="avatar">
											</li>
											<li class="avatar avatar-md">
												<img class="avatar-img circle" src="assets/img/team-4.jpg" alt="avatar">
											</li>
											<li class="avatar avatar-md">
												<div class="avatar-img circle bg-primary">
													<span class="text-white position-absolute top-50 start-50 translate-middle small">1K+</span>
												</div>
											</li>
										</ul>
									</div>

									<div>
										<h6 class="fw-normal mb-3">Rating</h6>
										<h6 class="m-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6>
									</div>
								</div>
							</div> */}
                </div>
              </div>

              <div class='col-xl-5 col-lg-6 col-md-6'>
                <div class='bestExperience-block'>
                  <h2 class='fw-bold lh-base'>
                    Our Experience And Services For you!
                  </h2>
                  <p
                    class='fw-light fs-6 text-justify mt-2'
                    style={{ color: '#5d6f7d' }}
                  >
                    Alhijaz Tours is well known within the Hajj and Umrah
                    industry. We have a highly experienced team who know the ins
                    and outs of Umrah and Hajj. We have immense experience on
                    how traveling and accommodation work within Makkah and
                    Medinah so we can make it easier for all pilgrims.
                    Additionally, we have an abundance of knowledge on how one
                    needs to perform their Hajj or Umrah and what they will need
                    to take with them on their journey. We provide all the
                    necessary information and keep our pilgrims updated at all
                    times to make your travels easier. Our highly experienced
                    and well versatile team is ready to take your queries.{' '}
                  </p>
                  <div class='d-inline-flex mt-4'>
                    <div class='d-inline-flex flex-column justify-content-center align-items-center py-3 px-3 rounded gray-simple me-4'>
                      <h6 class='fw-bold fs-3 m-0'>25</h6>
                      <p class='m-0 text-sm text-muted-2 mt-2'>
                        Years Experience
                      </p>
                    </div>
                    <div class='d-inline-flex flex-column justify-content-center align-items-center py-3 px-3 rounded gray-simple me-4'>
                      <h6 class=' fs-3 m-0'>500K+</h6>
                      <p class='m-0 text-sm text-muted-2 mt-2'>
                        Umrah Customers
                      </p>
                    </div>
                    <div class='d-inline-flex flex-column justify-content-center align-items-center py-4 px-4 rounded gray-simple '>
                      <h6 class='fw-bold fs-3 m-0'>12K+</h6>
                      <p class='m-0 text-sm text-muted-2 mt-2'>
                        Hajj Customers
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Hotels/> */}
        <MorePackages />
        <div
          class='elfsight-app-30c423b9-ae1d-4cd7-9b23-c53189e57dd5 mt-5'
          data-elfsight-app-lazy
        ></div>
        <div className='hide-review-footer'></div>
      </Layout>
      {/* {bottomAdd && (
        <div class='phone-only'>
          <div class='container position-relative'>
            <div class='phone-only-img'>
              <img
                class='phone-only-agent'
                width='68'
                height='68'
                alt='Call us at0121 777 2522'
                src={agentpic}
              />
            </div>
            <b>
              Looking for last-minute deals? Just give us a call to get
              phone-only deals!
            </b>
            <br />
            Call us at
            <a
              title='0121 777 2522'
              role='button'
              class='phonelink px-1'
              href='tel:0121 777 2522'
            >
              <span class='phone-number'>0121 777 2522</span>
            </a>
            and get assistance.
            <span class='cross-icon' onClick={hideadd}>
              ╳
            </span>
          </div>
        </div>
      )} */}

      {/* <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalBody>
          <div className='form-group'>
            <div class="modal-body booking-option-wrapper">
              <div class="modal-close-icon" onClick={toggleModal} tabindex="0" aria-label="close">
              <FontAwesomeIcon icon={faTimes}/>
                </div>
                <div class="expert-wrapper">
                  <div class="expert-image">
                    </div>
                    <div class="expert-availability">Available now</div>
                    </div>
                    <div class="booking-option-details">
                      <div class="booking-option-title" tabindex="0" aria-label="Let Us Help You Book!">Let Us Help You Book!</div>
                      <div class="booking-option-subtitle" tabindex="0" aria-label="[object Object]"><span><b>Speak with a travel expert and get assistance 24/7 </b></span></div>
                      <div class="booking-mobile"><a href="tel:0121 777 2522" title="Phone number" role="button" tabindex="0" aria-label="0121-777-2522">
                       <FontAwesomeIcon icon={faPhone}/> 0121 777 2522</a></div></div></div>
          </div>
          <div class="container content-section mb-0 popup-benefits">
          <h2 class="wHeding">Why to Book with Call Center</h2>
          <div class="row mt-2">
            <div class="col-lg-6 col-sm-6 content-section__main">
              <div class="row">
                <span class="col-3 pr-0">
                  <FontAwesomeIcon size='2x' className='st1' icon={faHeadphones}/>
                  </span>
                  <span class="col-9 pt-0"><h3 class="mb-2 font-weight-normal">Expert guidance by our <b class="d-block">Travel experts</b></h3></span></div></div>
                  <div class="col-lg-6 col-sm-6 content-section__main"><div class="row"><span class="col-3 pr-0">
                  <FontAwesomeIcon size='2x' className='st1' icon={faEnvelope}/>

                    </span>
                    <span class="col-9 pt-0"><h3 class="mb-2 font-weight-normal">Immediate <b class="d-block">booking confirmation</b></h3></span></div></div>
                    <div class="col-lg-6 col-sm-6 content-section__main"><div class="row"><span class="col-3 pr-0">
                    <FontAwesomeIcon size='2x' className='st1' icon={faClock}/>
                      </span><span class="col-9 pt-0"><h3 class="mb-2 font-weight-normal"><b class="d-block">24-hour </b>cancellation</h3></span></div></div>
                      <div class="col-lg-6 col-sm-6 content-section__main">
                        <div class="row"><span class="col-3 pr-0">
                        <FontAwesomeIcon size='2x' className='st1' icon={faMoneyBill}/>
                          </span><span class="col-9 pt-0"><h3 class="mb-2 font-weight-normal"><b class="d-block">Flexible payment</b> plans</h3></span></div></div></div>
          </div>
        </ModalBody>
      </Modal> */}
      <Modal isOpen={modalOpen} className='home_modal' toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Al Hijaz Tours</ModalHeader>
        <ModalBody>
          <NavLink to='/hajj'>
            <img
              class='phone-only-agent'
              width='auto'
              height='auto'
              alt='Call us at 0121 777 2522'
              src={adimage}
            />
          </NavLink>
          {/* <div className='text-center   mt-2 align-self-center'>
                <a target='blank' href='https://buy.stripe.com/7sI03q01N42Bbuw7ss'> <button className='btn btn-primary '  >Book Now</button></a>
                            </div> */}
        </ModalBody>
      </Modal>
    </>
  )
}

export default Home
